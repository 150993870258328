/**
 * @description contains offer information
 */
 export class Payment {
    link: string;

    constructor(link: string) {
        this.link = link;
    }

}
