import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PaymentComponent } from './config/payment/payment.component';
import { OffersComponent } from './config/offers/offers.component';
import { AddComponent } from './config/offers/add/add.component';
import { GlobalComponent } from '@app/dashboard/global/global.component';

import { AuthGuard, GrantGuard } from './_helpers';

const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const usersModule = () => import('./users/users.module').then(x => x.UsersModule);

const routes: Routes = [
    { path: '', component: GlobalComponent, canActivate: [AuthGuard] },
    { path: 'offers', component: OffersComponent, canActivate: [AuthGuard, GrantGuard] },
    { path: 'offers/add', component: AddComponent, canActivate: [AuthGuard, GrantGuard] },
    { path: 'offers/edit/:id', component: AddComponent, canActivate: [AuthGuard, GrantGuard] },
    { path: 'payment', component: PaymentComponent, canActivate: [AuthGuard] },
    { path: 'users', loadChildren: usersModule, canActivate: [AuthGuard, GrantGuard] },
    { path: 'account', loadChildren: accountModule },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }