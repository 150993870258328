import { Profil } from './index';

export class User {
    id: number;
    password: string;
    firstName: string;
    lastName: string;
    email: string;
    address: string;
    profil: Profil;
    partner: string;
    phone: string;
    status: boolean;
    access_token: string;
}
