<div class="row" *ngIf="user">
    <div class="col-md-12">
        <app-header></app-header>
    </div>
    

    <div class="col-md-2 none-padding">
        <app-aside></app-aside>
    </div>

    <div class="col-md-10 p-3 main-content">
        <div class="col-md-12">
            
        </div>
        <alert></alert>
        <router-outlet></router-outlet>
    </div>

</div>

<div class="" *ngIf="!user">
    <alert></alert>
    <router-outlet></router-outlet>
</div>
