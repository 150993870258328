import { Component, OnInit } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TransactionService } from '@app/_services/transaction.service';
import { Subscription } from 'rxjs';
import * as moment from "moment";

@Component({
	selector: 'app-filter',
	templateUrl: './filter.component.html',
	styleUrls: ['./filter.component.css']
})

export class FilterComponent implements OnInit {

	hoveredDate: NgbDate | null = null;
	routerId = '';
	referenceNumber = '';
	email = '';
	numTel = '';

	fromDate: NgbDate | null;
	toDate: NgbDate | null;
	transactionSubscription: Subscription;

	constructor(private calendar: NgbCalendar,
		public formatter: NgbDateParserFormatter,
		private transactionService: TransactionService) {

		let range = this.getWeekRange();
		let firstDay = moment(range[0].firstday);
		let lastday = moment(range[0].lastday);

		this.fromDate = new NgbDate(firstDay.year(), firstDay.month() + 1, firstDay.date());
		this.toDate = new NgbDate(lastday.year(), lastday.month() + 1, lastday.date());

		this.setWeekRange();
	}

	ngOnInit(): void {
		/* this.transactionSubscription = this.transactionService.transactionsSubject.subscribe(
			(transactions: any[]) => {
				console.log('transactions in filter : ', transactions);
				this.setWeekRange();
			}
		);
		this.transactionService.emitTransactions(); */
	}

	onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
	}

	isHovered(date: NgbDate) {
		return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
	}

	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}

	onSubmit() {
		this.setWeekRange();
	}

	setWeekRange() {
		this.transactionService.setWeekRange({
			startDate: this.fromDate,
			endDate: this.toDate,
			routerNumber: this.routerId,
			referenceNumber: this.referenceNumber,
			email: this.email,
			phone: this.numTel
		});
	}

	getWeekRange() {
		let lastday = moment(); // - curr.getDay(); // First day is the day of the month - the day of the week
		let firstday = moment().subtract(6, "days") // last day is the first day + 6

		return [{
			firstday,
			lastday
		}];
	}

}
