<div class="row">

    <div class="col-md-12">

        <div class="col-md-12 block-logo-canal">
            <div class="logo-canal">    
                <img src="/assets/img/logo-canal-box.png" alt="Logo Bizao" width="40px"/>
            </div>
            <strong>
                CanalBox
            </strong>
        </div>

        <ul class="nav flex-column menu-list" ngbNav #nav="ngbNav" [(activeId)]="active">
            <!-- Dashboard -->
            <li class="nav-item">
                <a class="nav-link disabled" href="#">
                    Dashboard
                </a>
            </li>

            <li class="nav-item" ngbNavItem="top">
                <a class="nav-link gva-menu-item " routerLink="dashboard">
                    <img
                        src="/assets/img/icons/dashboard.svg"
                        alt="dashboard"
                        height="20px"
                        width="30px" />
                    Global
                </a>
            </li>

            <!-- Configuration -->
            <li class="nav-item">
                <label class="nav-link disabled" href="#">
                    Configuration
                </label>
            </li>

            <li class="nav-item" ngbNavItem="middle">
                <a class="nav-link gva-menu-item " routerLink="payment">
                    <img
                        src="/assets/img/icons/payment.svg"
                        alt="dashboard"
                        height="15px"
                        width="30px" />
                    Lien de paiement
                </a>
            </li>

            <li class="nav-item" ngbNavItem="bottom" *ngIf="user.profil.code == 'admin'">
                <a class="nav-link gva-menu-item " routerLink="offers">
                    
                    <img
                        src="/assets/img/icons/offer.svg"
                        alt="dashboard"
                        height="15px"
                        width="30px" />
                    Gestion des offres
                </a>
            </li>

            <li class="nav-item" ngbNavItem="underbottom" *ngIf="user.profil.code == 'admin'">
                <a class="nav-link gva-menu-item " routerLink="users">
                    <img
                        src="/assets/img/icons/users.svg"
                        alt="dashboard"
                        height="15px"
                        width="30px" />
                    Gestion des utilisateurs
                </a>
            </li>
        </ul>

    </div>

</div>
