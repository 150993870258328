import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Profil } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class ProfilService {

    private profilSubject: BehaviorSubject<Profil>;
    public profil: Observable<Profil>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.profilSubject = new BehaviorSubject<Profil>(new Profil(null, '', '', false));
        this.profil = this.profilSubject.asObservable();
    }

    public get profilValue(): Profil {
        return this.profilSubject.value;
    }

    add(profil: Profil) {
        return this.http.post(`${environment.ppApi}/profil/`, profil);
    }

    getAll() {
        return this.http.get<Profil[]>(`${environment.ppApi}/profil`);
    }

    getById(id: string) {
        return this.http.get<Profil>(`${environment.ppApi}/profil/${id}`);
    }

    update(id, params) {
        return this.http.put(`${environment.ppApi}/profil/${id}`, params)
            .pipe(map(x => {
                return x;
            }));
    }

    delete(id: number) {
        return this.http.delete(`${environment.ppApi}/profil/${id}`)
            .pipe(map(x => {
                return x;
            }));
    }
}
