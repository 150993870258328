import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
// used to create fake backend

import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor, fakeBackendProvider } from './_helpers';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbPopoverModule, NgbDropdownModule, NgbCollapseModule, 
         NgbPaginationModule, NgbModalModule, NgbDatepickerModule, 
         NgbNavModule, NgbAccordionModule
        } from '@ng-bootstrap/ng-bootstrap';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { UiSwitchModule } from 'ngx-ui-switch';


import { AppComponent } from './app.component';
import { AlertComponent } from './_components';
import { OffersComponent } from '@app/config/offers/offers.component';
import { PaymentComponent } from '@app/config/payment/payment.component';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './header/header.component';
import { AsideComponent } from './aside/aside.component';
import { GlobalComponent } from './dashboard/global/global.component';
import { AmountCardComponent } from './dashboard/amount-card/amount-card.component';
import { FilterComponent } from './dashboard/filter/filter.component';
import { TransactionTableComponent } from './dashboard/transaction-table/transaction-table.component';

import { AddComponent } from './config/offers/add/add.component';
import { OfferService } from './_services/offer.service';
import { TransactionService } from './_services/transaction.service';
import { ChartComponent } from './dashboard/chart/chart.component';
import { ChartsModule } from 'ng2-charts';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { DataTablesModule } from "angular-datatables";
import { MatTableModule  } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BnNgIdleService } from 'bn-ng-idle';

registerLocaleData(localeFr, 'fr');

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        FontAwesomeModule,
        NgbPopoverModule,
        NgbDropdownModule,
        NgbCollapseModule,
        NgbPaginationModule,
        MDBBootstrapModule,
        FormsModule,
        UiSwitchModule.forRoot({
            size: 'small',
            color: 'rgb(0, 189, 99)',
            switchColor: '#1ba231',
            defaultBgColor: '#b9e4c0',
            defaultBoColor : '#b9e4c0',
            checkedLabel: 'on'
        }),
        NgbModalModule,
        NgbDatepickerModule,
        ChartsModule,
        NgbNavModule,
        DataTablesModule,
        NgbAccordionModule,
        MatTableModule,
        MatPaginatorModule,
        BrowserModule,
        BrowserAnimationsModule
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        OffersComponent,
        AddComponent,
        PaymentComponent,
        LayoutComponent,
        HeaderComponent,
        AsideComponent,
        LayoutComponent,
        GlobalComponent,
        AmountCardComponent,
        FilterComponent,
        TransactionTableComponent,
        ChartComponent
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: localeFr, useValue: 'fr' },
        OfferService,
        TransactionService,
        // provider used to create fake backend
        fakeBackendProvider,
        BnNgIdleService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
