import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-amount-card',
  templateUrl: './amount-card.component.html',
  styleUrls: ['./amount-card.component.css']
})
export class AmountCardComponent implements OnInit {

  @Input() amount: string;
  @Input() title: string;
  @Input() badge: string;
  @Input() description: string;
  @Input() currency: string;
  @Input() pourcentage: number;

  constructor() { 
    this.amount = '';
    this.title = '';
    this.badge = '';
    this.description = '';
  }

  ngOnInit(): void {
  }

  isSuccess() {
    return this.badge === 'success';
  }

  isFails() {
    return this.badge === 'failed';
  }

  isMoney() {
    return this.currency != 'no';
  }

}
