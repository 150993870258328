import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Formule } from '@app/_models/';

@Injectable({ providedIn: 'root' })
export class FormuleService {

    private formuleSubject: BehaviorSubject<Formule>;
    public formule: Observable<Formule>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.formuleSubject = new BehaviorSubject<Formule>(new Formule(0, '', ''));
        this.formule = this.formuleSubject.asObservable();
    }

    public get durationValue(): Formule {
        return this.formuleSubject.value;
    }

    add(formule: Formule) {
        return this.http.post(`${environment.ppApi}/offer/`, formule);
    }

    getAll() {
        return this.http.get<Formule[]>(`${environment.ppApi}/offer`);
    }

    getById(id: number) {
        return this.http.get<Formule>(`${environment.ppApi}/offer/${id}`);
    }

    update(id, params) {
        return this.http.put(`${environment.ppApi}/offer/${id}`, params)
            .pipe(map(x => {
                return x;
            }));
    }

    delete(id: number) {
        return this.http.delete(`${environment.ppApi}/offer/${id}`)
            .pipe(map(x => {
                return x;
            }));
    }
}
