import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { Transaction } from '@app/_models/transaction';
import { TransactionService } from '@app/_services/transaction.service';
import { Subscription } from 'rxjs';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.less']
})

export class ChartComponent implements OnInit, AfterViewInit {

  transactions: Transaction[];
  fullTransactions: Transaction[];
  weekRange: any[];
  transactionSubscription: Subscription;

  dataChart = [];
  successDataChart = [];

  currentDate: Date;

  public lineChartData: ChartDataSets[] = [
    { data: this.dataChart, label: 'Transactions' },
    { data: this.successDataChart, label: 'Transactions success' }
  ];

  dayTab = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  public lineChartLabels = [];
  public lineChartOptions: ChartOptions = {
    responsive: true,
  };
  public lineChartColors: Color[] = [
    {
      borderColor: '#F8AC3D',
      backgroundColor: 'transparent',
    },
    {
      borderColor: '#85cc94',
      backgroundColor: 'transparent',
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  constructor(private transactionService: TransactionService) {}

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.transactionSubscription = this.transactionService.transactionsSubject.subscribe(transactions => {
        this.transactions = transactions;
        this.loadDataChartFromTransactionSize();
    });
  }

  getTotalWeeklyTransactions() {}

  getTransactionChartDataFromamount() {
    this.dataChart = [];
    this.lineChartLabels = [];

    this.transactions.forEach((transc, i) => {
      
      if (i === 0) {
        this.dataChart[0] = transc.amount == null ? 0 : transc.amount;
        let dayIndex = new Date(transc.date).getDay();
        this.lineChartLabels[0] = this.dayTab[dayIndex - 1] + ', ' + new Date(transc.date).getDate();
      } else {
        let transactionDate = new Date(transc.date);
        let transactionNgDate = new NgbDate(transactionDate.getFullYear(), transactionDate.getMonth() + 1, transactionDate.getDate());

        let previousTransactionDate = new Date(this.transactions[i - 1].date);
        let previousTransactionNgDate = new NgbDate(
            previousTransactionDate.getFullYear(), previousTransactionDate.getMonth() + 1, previousTransactionDate.getDate()
        );

        if(transactionNgDate.equals(previousTransactionNgDate)) {
          let amount = transc.amount == null ? 0 : transc.amount;

          this.dataChart[this.dataChart.length - 1] += amount;
          let dayIndexPrevious = new Date(transc.date).getDay();
          this.lineChartLabels[this.dataChart.length - 1] = 
            this.dayTab[dayIndexPrevious - 1] + ', ' + new Date(transc.date).getDate();
        } else {
          this.dataChart[this.dataChart.length] = transc.amount;
          let newDateIndex = new Date(transc.date).getDay();
          this.lineChartLabels[this.dataChart.length] = this.dayTab[newDateIndex - 1] + ', ' + new Date(transc.date).getDate();
        }
      }
    });
    this.lineChartData = [
      { data: this.dataChart, label: 'Transactions' },
    ];
  }

  loadDataChartFromTransactionSize() {
    this.dataChart = [];
    this.successDataChart = [];
    this.lineChartLabels = [];
    this.transactions.slice().reverse().forEach((transc, i) => {
        if (i <= this.transactions.length - 1) {
          if (i === 0) {
              this.dataChart[0] = 1;

              this.successDataChart[0] = (transc.status == 'success' || transc.status == 'successful') ? 1 : 0;

              let dayIndex = new Date(transc.date).getDay();
              this.lineChartLabels[0] = this.dayTab[dayIndex] + ', ' + new Date(transc.date).getDate();
          } else {
              let transactionDate = new Date(transc.date);
              let transactionNgDate = new NgbDate(
                  transactionDate.getFullYear(),
                  transactionDate.getMonth() + 1,
                  transactionDate.getDate()
              );

              let previousTransactionDate = new Date(this.transactions.slice().reverse()[i - 1].date);
              let previousTransactionNgDate = new NgbDate(
                  previousTransactionDate.getFullYear(),
                  previousTransactionDate.getMonth() + 1,
                  previousTransactionDate.getDate()
              );

              if(transactionNgDate.equals(previousTransactionNgDate)) {
                  this.dataChart[this.dataChart.length - 1] ++;
                  let dayIndexPrevious = new Date(transc.date).getDay();

                  this.lineChartLabels[this.dataChart.length - 1] = this.dayTab[dayIndexPrevious] + ', ' + new Date(transc.date).getDate();

                  if (transc.status == 'success' || transc.status == 'successful') {
                      let successTransc = this.successDataChart[this.dataChart.length - 1];
                      this.successDataChart[this.dataChart.length - 1] = successTransc ? successTransc + 1 : 1;
                  } else {
                      let successTransc = this.successDataChart[this.dataChart.length - 1];
                      this.successDataChart[this.dataChart.length - 1] = successTransc ? successTransc : 0;
                  }
              } else {
                  this.dataChart[this.dataChart.length] = 1;
                  let newDateIndex = new Date(transc.date).getDay();
                  this.lineChartLabels[this.dataChart.length - 1] = this.dayTab[newDateIndex] + ', ' + new Date(transc.date).getDate();

                  this.successDataChart[this.dataChart.length - 1] = (transc.status == 'success' || transc.status == 'successful') ? 1 : 0;
              }
          }
        }
    });

    this.lineChartData = [
      { data: this.dataChart, label: 'Transactions' },
      { data: this.successDataChart, label: 'Transactions success' },
    ];
  }

  isTransactionDateEquals() {
  }

}
