/**
 * @description contains profil information
 */
 export class Profil {
    id: number;
    code: string;
    libelle: string;
    status: boolean;

    constructor(id: number, code: string, libelle: string, status: boolean) {
        this.id = id;
        this.code = code;
        this.libelle = libelle;
        this.status = status;
    }

}
