<div class="col-md-12 none-padding">

    <div class="col-md-12 border-bottom block-header padding">
        <h5>
            Détails des transactions
        </h5>

        <button class="shadow-sm bg-white white-button float-right content-shadow" (click)="downloadExcel()">
            <img _ngcontent-agq-c177="" src="/assets/img/icons/csv.svg" 
                alt="dashboard" height="15px" width="30px">
            Export excel
        </button>

        <button class="shadow-sm bg-white white-button float-right mr-15 content-shadow" 
            (click)="refrechTransactionStatus()"
            title = "Rafraichir les transactions InProgress"
            style="padding: 5px 10px 5px 10px;">
            <img _ngcontent-agq-c177 = "" src = "/assets/img/reload.png" 
                alt = "dashboard" height = "30px" width = "30px"
                [ngClass]="{
                    'image-animation': getLoaderRefresh()
                }"/>
        </button>

        <button class="shadow-sm bg-white white-button float-right mr-15 content-shadow" 
            (click)="refrechFailedTransaction()"
            title = "Rafraichir une transaction Failed"
            style="padding: 5px 10px 5px 10px;">

            <img _ngcontent-agq-c177 = "" src = "/assets/img/refresh-falied.png" 
                alt = "dashboard" height = "30px" width = "30px"
                [ngClass]="{
                    'image-animation': getFailedLoader()
                }"/>
        </button>

        <!-- <button class="shadow-sm bg-white white-button float-right mr-15" (click)="downloadCsv()">
            <img _ngcontent-agq-c177="" src="/assets/img/icons/csv.svg" 
                alt="dashboard" height="15px" width="30px">
            Export CSV
        </button> -->
    </div>

    <table class="table border-bottom">

        <thead>
            <tr>
                <th *ngFor="let head of headElements; let i = index" scope="col">
                    {{head}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="!getLoader()">
                <td colspan="10" class="text-center">
                    <span class="spinner-border spinner-border-lg align-center"></span>
                </td>
            </tr>
            <tr *ngFor="let el of currentTransactionsTable; let i = index">

                <th scope="row">{{ (i + 1) + (page * 10) }}</th>

                <td>
                    {{ el.date | date:'dd-MM-YYYY HH:mm:ss' }}
                </td>
                
                <td>
                    {{ el.referenceNumber }}
                </td>

                <td>
                    {{ el.routers ? el.routers.code : '-' }}
                </td>

                <td>
                    {{ el.user ? el.user.firstName : '-' }}
                </td>

                <td>
                    {{ el.user ? el.user.lastName : '-' }}
                </td>

                <td>
                    {{ el.phone ? el.phone : el.user.phone }}
                </td>

                <td>
                    {{el.amount}}
                </td>

                <td>
                    <!-- {{ el.mno == 'orange' || el.mno == 'free' || el.mno == 'mtn' || el.mno == 'moov' ? 'Mobile Money' : 'Carte bancaire' }} -->
                    {{ el.paymentMethod != null ? el.paymentMethod : '-' }}
                </td>

                <td>
                    <span class="badge"
                        [ngClass]="{
                            'badge-warning': el.status.toLowerCase() === 'inprogress',
                            'badge-danger': el.status.toLowerCase() === 'failed',
                            'badge-success': el.status.toLowerCase() === 'success' || el.status.toLowerCase() === 'successful',
                            'badge-primary': el.status.toLowerCase() === 'expired',
                            'badge-secondary': el.status.toLowerCase() === 'abandonned'
                        }">
                        {{el.status}}
                    </span>
                </td>

                <td>
                    <button class="action-button mr-2" id="dropdownManual" (click)="open(content, el.id)">
                        <img _ngcontent-agq-c177="" src="/assets/img/details.png" 
                            alt="dashboard" width="20px">
                    </button>
                </td>
            </tr>

        </tbody>
    </table>

    <div class="mb-25 mt-25 col-lg 12 container-fluid float-left">
        <button class="content-shadow white-button float-right" (click)="nextPage()">
            Suivant
        </button>

        <button class="content-shadow white-button float-right mr-15" (click)="previousPage()">
            Précédent
        </button>

        <div class="float-right mr-25 gva-table-pagination">
            Pages <strong>{{ page + 1 }}</strong> sur <strong>{{ pageSize == 0 ? 1 : pageSize }}</strong>
        </div>
        
    </div>


    <ng-template #content let-modal>
        <div class="modal-body none-padding">
            <div class="col-md-12 none-padding">
                <div class="row">
                    <div class="col-md-5 left-block text-center">
                        <img _ngcontent-agq-c177="" src="/assets/img/list-current-transaction.png" 
                            alt="dashboard" width="100px">
                        <h5>
                           N° {{ currentTransaction[0].referenceNumber }}
                        </h5>
                        <h3>
                            {{ currentTransaction[0].amount }} FCFA
                        </h3>

                        <h6>
                            {{ currentTransaction[0].date | date:'fullDate':'UTC' }}
                        </h6>
                    </div>

                    <div class="col-md-7 rigth-block">
                        <h6> Details transaction </h6>
                        <div class="row">
                            <div class="col-md-5">
                                <ul class="list-item-title">
                                    <li>
                                        Date :
                                    </li>
                                    <li>
                                        Transaction ID :
                                    </li>
                                    <li>
                                        Numéro de routeur :
                                    </li>

                                    <li>
                                        Prénom :
                                    </li>
                                    <li>
                                        Nom :
                                    </li>
        
                                    <li>
                                        E-mail :
                                    </li>

                                    <li>
                                        Téléphone :
                                    </li>

                                    <li>
                                        Offre :
                                    </li>

                                    <li>
                                        country :
                                    </li>
                                    <li>
                                        Opérateur :
                                    </li>
                                    <li>
                                        Partner reference :
                                    </li>
                                    <li>
                                        Montant :
                                    </li>
                                    <li>
                                        Devise :
                                    </li>
                                    <li>
                                        Statut :
                                    </li>
                                </ul>
                            </div>
                            
                            <div class="col-md-7">
                                <ul class="list-item-values">
                                    <li>
                                        {{ currentTransaction[0].date | date:'fullDate':'UTC' }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].partnerReference }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].routers ? currentTransaction[0].routers.code : '-' }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].user ? currentTransaction[0].user.firstName : '-' }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].user ? currentTransaction[0].user.lastName : '-' }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].user ? currentTransaction[0].user.email : '-' }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].phone ? currentTransaction[0].phone : currentTransaction[0].user.phone }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].package ? currentTransaction[0].package.offer.label : '-'  }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].country }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].mno || 'Bank' }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].partnerReference }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].amount }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].currency }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].status }}
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <!-- <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Transaction</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div> -->

                
        </div>
    </ng-template>

</div>
