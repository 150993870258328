import { Component, OnInit } from '@angular/core';
import { AccountService } from '../_services';
import { User } from '../_models';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  collapsed = true;

  user: User;
  
  constructor(private accountService: AccountService) {
        this.accountService.user.subscribe(x => this.user = x);
  }

  logout() {
      this.accountService.logout();
  }

  ngOnInit(): void {
  }

}
