import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Transaction } from '@app/_models/transaction';
import { TransactionService } from '@app/_services/transaction.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-global',
  templateUrl: './global.component.html',
  styleUrls: ['./global.component.css']
})
export class GlobalComponent implements OnInit, AfterViewInit {

  transactionSubscription: Subscription = new Subscription();
  transactions: Transaction[] = null;
  loader = false;

  totalAmount: number = 0;
  totalAmountSuccess: number = 0;
  totalFailed: number = 0;
  totalSuccess: number = 0;
  totalTransaction: number = 0;

  constructor(private transactionService: TransactionService) { }

  ngAfterViewInit(): void {
    /* this.transactionSubscription = this.transactionService.transactionsSubject.subscribe(
      (transactions: any[]) => {
        this.transactions = transactions;
        this.calculateAllAmount();
        this.totalTransaction = this.transactions.length;
        this.loader = true;
      }
    );*/
  }

  getLoader () {
    return this.transactionService.loader;
  }

  ngOnInit(): void {
    this.transactionSubscription = this.transactionService.transactionsSubject.subscribe(
      (transactions: any[]) => {
        this.transactions = transactions;
        this.calculateAllAmount();
        this.totalTransaction = this.transactions.length;
        this.loader = true;
      }
    );
  }

  calculateTotalAmount(transactions: Transaction[]) {
    let total = 0;
    transactions.forEach(transaction => {
      total += transaction.amount ? transaction.amount : 0;
    });

    return total;
  }

  calculateTotalSucceessAmount(transactions: Transaction[]) {
    let total = 0;
    transactions.forEach(transaction => {
      if(transaction.amount && (transaction.status == 'success' || transaction.status == 'successful')) {
        total += transaction.amount;
      }
      
    });

    return total;
  }

  getSuccessTransaction() {
    return this.transactions ? this.transactions.filter(transaction => transaction.status == 'success' || transaction.status == 'successful') : [];
  }

  getFailedTransaction() {
    return this.transactions ?  this.transactions.filter(transaction => transaction.status == 'failed') : [];
  }

  getPourcentageSuccessTransaction () {
    return this.transactions ? parseInt(this.getSuccessTransaction().length / this.transactions.length * 100 + '') : 0;
  }

  getPourcentageFailedTransaction () {
    return this.transactions ? parseInt(this.getFailedTransaction().length / this.transactions.length * 100 +  '') : 0;
  }

  calculateAllAmount() {
    this.totalAmount = this.calculateTotalAmount(this.transactions);
    this.totalAmountSuccess = this.calculateTotalSucceessAmount(this.transactions);
    // this.totalSuccess = this.calculateTotalAmount(this.getSuccessTransaction());
    this.totalSuccess = this.getSuccessTransaction().length;
    // this.totalFailed = this.calculateTotalAmount(this.getFailedTransaction());
    this.totalFailed = this.getFailedTransaction().length;
  }

}
