<div class="col-md-12 padding">

    <div class="row">

        <div class="col-md-4">
            <strong>App</strong> > <span class="breadcrumbs-color"> Dashboard </span>
        </div>

        <div class="col-md-8">
            <div class="container-fild">
                <app-filter></app-filter>
            </div>
        </div>

        <div class="col-md-12 shadow-sm bg-white rounded content-shadow">
            <div class="row">

                <div class="col-md-6 border-right padding">
                    <div *ngIf="!getLoader()">
                        <span class="spinner-border spinner-border-lg align-center"></span>
                    </div>
                    <app-chart ></app-chart>
                </div>

                <div class="col-md-6 padding">
                    <div class="row">
                        <app-amount-card class="col-md-12 border-bottom text-center"
                            [amount]="totalAmountSuccess"
                            title="Montant collecté"
                            badge="none"
                            description="Montant total des transactions à succés"
                            currency="XOF"></app-amount-card>

                        <app-amount-card class="col-md-6 border-right"
                            [amount]="totalSuccess" 
                            title="Success" 
                            badge="success"
                            description="Total du trafic (success)"
                            currency=""
                            pourcentage="{{ getPourcentageSuccessTransaction() }}"></app-amount-card>

                        <!-- <app-amount-card class="col-md-6 border-right"
                            [amount]="totalTransaction" 
                            title="Nombre de transactions" 
                            badge="none" 
                            description="Nombre total des transactions effectuées"
                            currency="no"></app-amount-card> -->
                        
                        <app-amount-card class="col-md-6"
                            [amount]="totalFailed"
                            title="Fails"
                            badge="failed"
                            description="Total du trafic (fails)"
                            currency=""
                            pourcentage="{{ getPourcentageFailedTransaction() }}"></app-amount-card>  
                    </div>
                </div>

            </div>
        </div>

        <div class="col-md-12 none-padding shadow-sm bg-white rounded transaction-table content-shadow">
            <div class="col-lg-12">

                <app-transaction-table ></app-transaction-table>

            </div>
        </div>

    </div>

</div>
