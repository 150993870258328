<div class="col-md-12 breadcrumbs-block">
    <div class="row">
        
        <div class="col-md-6">
            <strong>App</strong> <span class="breadcrumbs-color"> > Configuration > Lien de paiement</span>
        </div>

        <div class="col-md-6">
        </div>
    </div>
</div>

<div class="col-md-12 shadow-sm bg-white rounded content-shadow">

    
    <div class="col-md-12 border-bottom block-header padding">
        <h5>
            Lien de paiement
        </h5>
    </div>
    
    <div class="col-md-12 padding">
        <div class="row">
                
            <form (ngSubmit)="onSubmitUserData()" [formGroup]="form" class="col-md-12 padding">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        
                        <div class="form-group row mt-25">
                            <label for="lastName"  class="col-sm-2 col-form-label">Nom</label>
                            <div class="col-sm-10">
                                <input type="text" formControlName="lastName" class="form-control" 
                                    [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" 
                                    placeholder="Votre nom"/>

                                <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                    <div *ngIf="f.lastName.errors.required">Nom est obligatoire</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row mt-25">
                            <label for="firstName"  class="col-sm-2 col-form-label">Prénom</label>
                            <div class="col-sm-10">
                                <input type="text" formControlName="firstName" class="form-control" 
                                    [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" 
                                    placeholder="Votre prénom" required/>

                                <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                    <div *ngIf="f.firstName.errors.required">Prénom est obligatoire</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row mt-25">
                            <label for="email"  class="col-sm-2 col-form-label">E-mail</label>
                            <div class="col-sm-10">
                                <input type="email" formControlName="email" class="form-control" 
                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" 
                                    placeholder="example@example.com"/>

                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">E-mail est obligatoire</div>
                                    <div *ngIf="f.email.errors.email">Le mail est incorrect</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row mt-25">
                            <label for="city"  class="col-sm-2 col-form-label">Ville</label>
                            <div class="col-sm-10">
                                
                                <input type="text" formControlName="city" class="form-control" 
                                    [ngClass]="{ 'is-invalid': submitted && f.city.errors }" 
                                    placeholder="Votre ville"/>

                                <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                    <div *ngIf="f.city.errors.required">Ville est obligatoire</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row mt-25">
                            <label for="country"  class="col-sm-2 col-form-label">Pays</label>
                            <div class="col-sm-10">
                                <select class="form-control" id="pays" name="country" formControlName="country"
                                    [ngClass]="{ 'is-invalid': submitted && f.country.errors }" required>
                                    <option value="ci" selected="true">Cote d'Ivoire</option>
                                </select>

                                <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                                    <div *ngIf="f.country.errors.required">Pays est obligatoire</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row mt-25">
                            <label for="phone"  class="col-sm-2 col-form-label">Numéro WhatsApp</label>
                            <div class="col-sm-10">
                                
                                <input type="number" formControlName="phone" class="form-control" 
                                    [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" 
                                    placeholder="225XXXXXXXXXX" maxlength="10"/>

                                <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                    <div *ngIf="f.phone.errors.required">Téléphone est obligatoire</div>
                                    <div *ngIf="f.phone.errors.maxLength">Téléphone doit etre egal à 10 chiffre</div>
                                    <div *ngIf="f.phone.errors.minlength">Téléphone doit etre egal à 10 chiffres</div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-lg-6 col-md-6">

                        <div class="form-group row mt-25">
                            <label for="routerNumber"  class="col-sm-2 col-form-label">Routeur</label>
                            <div class="col-sm-10">

                                <input type="number" formControlName="routerNumber" class="form-control" 
                                    [ngClass]="{ 'is-invalid': submitted && f.routerNumber.errors }"
                                    pattern="[A][L][C][L][0-9A-Z]{8}"
                                    placeholder="ALCL ... ..."/>

                                <div *ngIf="submitted && f.routerNumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.routerNumber.errors">Veuillez saisir les 14 chiffres de votre numéro de routeur commençant par ALCL.</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row mt-25">
                            <label for="colFormLabel" class="col-sm-2 col-form-label">Offre</label>
                            <div class="col-sm-10">
                                <select class="form-control" id="offer" name="offer"
                                    formControlName="offer"
                                    [ngClass]="{ 'is-invalid': submitted && f.offer.errors }" required>
                                    <option *ngFor="let package of packages"
                                        [ngValue]="package.id">
                                        {{ package.offer.label }} {{ package.duration.label }} {{ package.price }} XOF
                                    </option>
                                </select>
                                <div *ngIf="submitted && f.offer.errors" class="invalid-feedback">
                                    <div *ngIf="f.offer.errors.required">L'offre est obligatoire</div>
                                </div>
                            </div>
                        </div>


                        <div class="form-group row mt-25">
                            <label for="address"  class="col-sm-2 col-form-label">Adresse</label>
                            <div class="col-sm-10">

                                <input type="text" formControlName="address" class="form-control" 
                                    [ngClass]="{ 'is-invalid': submitted && f.address.errors }" 
                                    placeholder="Adresse"/>

                                <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                    <div *ngIf="f.address.errors.required">Adresse est obligatoire</div>
                                </div>
                            </div>
                        </div> 

                        <div class="form-group row mt-25">
                            <label for="currency" class="col-sm-2 col-form-label">Devise</label>
                            <div class="col-sm-10">
                                <select class="form-control" id="currency" name="currency"
                                    formControlName="currency"
                                    [ngClass]="{ 'is-invalid': submitted && f.currency.errors }" required disabled>
                                    <option value="xof">XOF</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row mt-25">
                            <label for="lastName"  class="col-sm-2 col-form-label">Lien</label>
                            <div class="col-sm-10">
                                <select class="form-control" id="time" name="time"
                                    formControlName="time"
                                    [ngClass]="{ 'is-invalid': submitted && f.time.errors }" required>
                                    <option value="10">10 Minutes</option>
                                    <option value="15">15 minutes</option>
                                    <option value="20">20 minutes</option>
                                </select>

                                <div *ngIf="submitted && f.time.errors" class="invalid-feedback">
                                    <div *ngIf="f.time.errors.required">Lien est obligatoire</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row mt-25">
                            <label for="lastName"  class="col-sm-2 col-form-label">Message</label>
                            <div class="col-sm-10">
                                <textarea id="message" name="message" formControlName="message" rows="6" cols="60">
                                </textarea>

                                <div *ngIf="submitted && f.time.errors" class="invalid-feedback">
                                    <div *ngIf="f.time.errors.required">Le message est obligatoire</div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-sm-12 row mt-25 mb-25">
                        <div class="col-sm-12 text-center">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            <button type="submit" class="orange-button padding-latge-button">Générer</button>
                        </div>
                    </div>

                </div>
            </form>

            <div id="login-img" class="col s6 cus-h-450 hide-on-small-only"></div>
        </div>
    </div>

</div>

<div class="col-md-12 shadow-sm bg-white rounded mt-25">

    <div class="col-md-12 border-bottom block-header padding">
        <h5>
            Partager ce lien avec le client
        </h5>
    </div>

    <div class="col-md-12 padding">
        <div class="col-lg-12 col-md-12">
            <form (ngSubmit)="sendLink(link)" #link="ngForm">
                <div class="row">

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group row mt-25">
                            <label for="prenom" class="col-sm-3 col-form-label">
                                Lien de paiement à Partager
                            </label>
                            <div class="col-sm-8 link-payment">
                                <!-- <fa-icon [icon]="faCopy"></fa-icon> -->
                                <input type="text" class="form-control" id="link" disabled
                                    placeholder="Lien" name="generatedLink" [(ngModel)]="lien" required>


                                <div class="form-group row mt-25">

                                    <div class="col-sm-6 col-md-6 col-lg-6 text-center">
                                        <button type="submit" class="orange-button padding-latge-button" [disabled]="link.invalid"
                                            (click)="open(contentShare)">
                                            Partager le lien
                                        </button>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </form>
        </div>
    </div>

</div>


<ng-template #contentShare let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Partager le lien</h4>
        <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">x</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="form-group share-link-block">
                <div class="row">
                    <div class="col-lg-6 col-md-6 text-center" (click)="sendLinkViaMail()">
                        <div class="col-lg-12 col-md-12 share-link-envelop text-center">
                            <img src="/assets/img/email.png" alt="canalBox" width="75px" />
                        </div>
                        <div class="col-lg-12 col-md-12 text-center mt-25">
                            <strong>
                                Partager par E-mail
                            </strong>
                            <span *ngIf="loadingSendMail" class="spinner-border spinner-border-sm mr-1 text-center"></span>
                            <br />
                            <span *ngIf="loadingSendMailStatus" class="text-green">
                                Lien partagé avec succés
                            </span>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6" (click)="sendLinkToWhatsapp()">
                        <div class="col-lg-12 col-md-12 share-link-whatsapp text-center">
                            <img src="/assets/img/whatsapp.png" alt="canalBox" width="75px" />
                        </div>
                        <div class="col-lg-12 col-md-12 text-center mt-25">
                            <strong>
                                Partager par whatsapp
                            </strong>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
