<div class="col-md-12 card-amount">
    <h5 class="title">
        {{ title }}
        <span class="badge badge-success" *ngIf="isSuccess()">{{ pourcentage }} %</span>
        <span class="badge badge-danger" *ngIf="isFails()">{{ pourcentage }} %</span>
    </h5>

    <h1 class="amount">
        {{ amount }} <span *ngIf="isMoney()">{{ currency }}</span>
    </h1>

    <span class="description">
        {{ description }}
    </span>

</div>
