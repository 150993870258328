import { Component, OnInit } from '@angular/core';
import { faUser, faLink, faDatabase } from '@fortawesome/free-solid-svg-icons';

import { AccountService } from '@app/_services';
import { User } from '@app/_models';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.css']
})
export class AsideComponent implements OnInit {

  faUserIcon = faUser;
  faLinkIcon = faLink;
  faDatabaseIcon = faDatabase;
  active = 'top';
  user: User;

  constructor(private accountService: AccountService) { }

  ngOnInit(): void {
    this.user = this.accountService.userValue;
  }

  changeActive(e) {
  }

}
