<div class="col-md-12 breadcrumbs-block">
    <div class="row">
        
        <div class="col-md-6">
            <strong>App</strong> <span class="breadcrumbs-color"> > Configuration > Gestion des offres</span>
        </div>

        <div class="col-md-6">
        </div>
    </div>
</div>

<div class="col-md-12 shadow-sm bg-white rounded content-shadow" style="float: left;">

    <div class="col-md-12 border-bottom block-header padding">
        <h5>
            Offres 
            <span class="badge badge-warning orange-background offers-number text-white">
                {{ offerSize }}
            </span>
        </h5>
        <a routerLink="/offers/add" class="orange-button float-right">
            Ajouter une offre
        </a>
    </div>

    <table mdbTable class="table z-depth-1 border-bottom">

        <thead>
            <tr>
                <th *ngFor="let head of headElements; let i = index" scope="col">
                    {{ head }}
                </th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let el of offers; let i = index">
                <ng-container *ngIf="el.offer && el.duration">
                    <th scope="row">
                        {{ (i + 1) + (page * 10) }}
                    </th>

                    <td>
                        {{ el.offer.label || '-' }} {{ el.offer.debit || '-' }}
                    </td>

                    <td>
                        {{ el.duration.label }}
                    </td>

                    <td>
                        {{ el.price }} F CFA
                    </td>

                    <td>
                        {{ el?.offer?.description }}
                    </td>

                    <td>
                        <ui-switch [(ngModel)]="el.status"
                            (valueChange)="onChangeOffer($event, el.id)"></ui-switch>
                    </td>

                    <td>
                        <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown" class="block-action">
                            <button class="action-button mr-2" id="dropdownManual" 
                                ngbDropdownAnchor (focus)="myDrop.open()">°°°</button>

                            <div ngbDropdownMenu aria-labelledby="dropdownManual" class="dropdown-action">
                                <a routerLink="edit/{{el.id}}"
                                    class="btn btn-sm btn-primary mr-1 btn-modifier">Edit</a>
                                
                                <button (click)="deleteOffer(el.id)" class="btn btn-sm btn-danger btn-delete-user btn-modifier" 
                                    [disabled]="el.isDeleting">
                                    <span *ngIf="el.isDeleting" class="spinner-border spinner-border-sm"></span>
                                    <span *ngIf="!el.isDeleting">Delete</span>
                                </button>
                            </div>
                        </div>
                    </td>

                </ng-container>
            </tr>
            <tr *ngIf="!offers">
                <td colspan="7" class="text-center">
                    <span class="spinner-border spinner-border-lg align-center"></span>
                </td>
            </tr>
        </tbody>
        <!-- <tfoot class="grey lighten-5 w-100">
            <tr>
                <td colspan="4">
                    <mdb-table-pagination [searchDataSource]="offers">

                    </mdb-table-pagination>
                </td>
            </tr>
        </tfoot> -->
    </table>

    <div class="mb-25 mt-25 col-lg 12 container-fluid float-left">
        <button class="content-shadow white-button float-right" (click)="nextPage()">
            Suivant
        </button>

        <button class="content-shadow white-button float-right mr-15" (click)="previousPage()">
            Précédent
        </button>

        <div class="float-right mr-25 gva-table-pagination">
            Pages <strong>{{ page + 1 }}</strong> sur <strong>{{ pageSize == 0 ? 1 : pageSize }}</strong>
        </div>
        
    </div>
</div>
