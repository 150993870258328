import { Subject } from "rxjs";
import { Transaction } from '../_models/transaction';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TransactionService {

    transactionsSubject = new Subject<Transaction[]>();
    weekRangeSubject = new Subject<any[]>();
    allTransactions: Transaction[];
    loader = false;
    loaderRefresh = false;
    loaderFailedRefresh = false;

    private transactions = [];
    private weekRange = [];

    constructor(private http: HttpClient) {
        this.weekRange = this.getWeekRange();

        /*this.fetchAll().subscribe(transactions => {
            this.transactions = transactions;
            this.allTransactions = transactions;
            console.log("transactions : ", transactions);
            this.emitTransactions();
            this.emitWeekRange();
            this.loader = true;
        });*/
    }

    emitTransactions() {
        this.transactionsSubject.next(this.transactions.slice());
    }

    emitWeekRange() {
        this.weekRangeSubject.next(this.weekRange.slice());
    }

    getAll() {
        return this.allTransactions;
    }

    fetchAll() {
        return this.http.get<Transaction[]>(`${environment.ppApi}/transaction`);
    }

    formatQueryParms(data): string {
        let resp = '?params=true';
        if (data.startDate) {
            resp += '&startDate=' + data.startDate.year + '-' + data.startDate.month + '-' + data.startDate.day;
        }
        if (data.endDate) {
            resp += '&endDate=' + data.endDate.year + '-' + data.endDate.month + '-' + data.endDate.day;
        }
        if (data.routerNumber) resp += `&routerNumber=${data.routerNumber}`;
        if (data.referenceNumber) resp += `&referenceNumber=${data.referenceNumber}`;
        if (data.phone) resp += `&phone=${data.phone}`;
        if (data.email) resp += `&email=${data.email}`;
        if (resp.endsWith('true')) return '';
        return resp;
    }

    fetchAllData(data: any) {
        return this.http.get<Transaction[]>(`${environment.ppApi}/transaction${this.formatQueryParms(data)}`);
    }



    getWeekRange() {
        let curr = new Date; // get current date
        let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        let last = first + 6; // last day is the first day + 6

        let firstday = new Date(curr.setDate(first));
        let lastday = new Date(curr.setDate(last));

        return [{
            firstday,
            lastday
        }];
    }

    setWeekRange(weekRange) {
        // this.transactions = weekRange.routerId && weekRange.routerId.length ? this.filterByRouterId(weekRange.routerId, this.allTransactions) : this.allTransactions;

        /*let transactions: Transaction[] = null;
        if (weekRange.routerId && weekRange.routerId.length) {
            transactions = this.filterByRouterId(weekRange.routerId, weekRange.referenceId, this.allTransactions);
        } else {
            transactions = this.allTransactions;
        }

        if (weekRange.referenceId && weekRange.referenceId.length > 0) {
            transactions =  this.filterByReferenceNumber(weekRange.referenceId, transactions);
        } else if(transactions == null) {
            transactions = this.allTransactions;
        }

        if (weekRange.email && weekRange.email.length > 0) {
            transactions = transactions.filter(transaction => transaction.email == weekRange.email);
        } else if(transactions == null) {
            transactions = this.allTransactions;
        }

        if (weekRange.numTel && weekRange.numTel.length > 0) {
            transactions = transactions.filter(transaction => transaction.user.phone == weekRange.numTel);
        } else if(transactions == null) {
            transactions = this.allTransactions;
        }*/

        /* if ((!weekRange.routerId && !weekRange.routerId.length) &&  
            (!weekRange.referenceNumber) && 
            (weekRange.email && weekRange.email.length)) {
                console.log('test');
                transactions = this.allTransactions;
        } */

        this.loader = false;

        this.fetchAllData(weekRange).subscribe((transactions) => {
            this.weekRange = [
                {
                    firstday: weekRange.startDate,
                    lastday: weekRange.endDate
                }
            ];
            this.transactions = transactions;
            this.allTransactions = transactions;
            console.log("transactions : ", transactions);
            this.emitTransactions();
            this.emitWeekRange();
            this.loader = true;
        });
    }

    

    updateTransactions(transactions: Transaction[], type: string) {
        const nbrTransaction = transactions.length;
        let cmpTrsanction = 0;
        type == 'failed' ? this.loaderFailedRefresh = true : this.loaderRefresh = true;
        transactions.forEach((transaction, index) => {
            if (transaction.mno) {
                const params = {
                    "country": transaction.country,
                    "mno": transaction.mno,
                    "orderId": transaction.referenceNumber,
                    "lang": "fr",
                    "channel": "web" 
                };
                this.http.post<any[]>(`${environment.updateStatusMMUrl}`, params).pipe(first())
                .subscribe({
                    next: (data: any) => {
                        cmpTrsanction ++;
                        this.updateRefreshLoader(nbrTransaction, cmpTrsanction);
                    },
                    error: error => {
                        cmpTrsanction ++;
                        this.updateRefreshLoader(nbrTransaction, cmpTrsanction);
                    }
                });
            } else {
                const params = {
                    "country": transaction.country,
                    "orderId": transaction.referenceNumber,
                };
                this.http.post<any[]>(`${environment.updateStatusCardUrl}`, params).pipe(first())
                .subscribe({
                    next: (data: any) => {
                        cmpTrsanction ++;
                        this.updateRefreshLoader(nbrTransaction, cmpTrsanction);
                    },
                    error: error => {
                        cmpTrsanction ++;
                        this.updateRefreshLoader(nbrTransaction, cmpTrsanction);
                    }
                });
            }
        });
    }

    updateRefreshLoader(nbrTransaction, cmpTransaction) {
        if (nbrTransaction == cmpTransaction) {
            this.loaderRefresh = false;
            this.loaderFailedRefresh = false;
            this.fetchAll().subscribe(transactions => {
                this.transactions = transactions;
                this.allTransactions = transactions;
                this.emitTransactions();
                this.emitWeekRange();
                this.loader = true;
            });
        }
    }

    filterByRouterId(routerId, referenceId, transactions) {
        return transactions.filter(transaction => transaction.routers.code == routerId);
    }

    filterByReferenceNumber(referenceNumber, transactions) {
        return transactions.filter(transaction => transaction.referenceNumber == referenceNumber);
    }

    filterTransactionsByDate(trasantions) {
        return trasantions.filter(transaction => {
            let transactionDate = new Date(transaction.date);
            let transactionNgDate = new NgbDate(transactionDate.getFullYear(), transactionDate.getMonth() + 1, transactionDate.getDate());

            let testDate = (transactionNgDate.after(this.weekRange[0].firstday) || transactionNgDate.equals(this.weekRange[0].firstday)) && 
            (transactionNgDate.before(this.weekRange[0].lastday) || transactionNgDate.equals(this.weekRange[0].lastday));
            
            return testDate ? transaction : false;
        });
    }
}
